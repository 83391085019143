<template>
    <div class="container">
        <common-header>{{title}}</common-header>
        <div class="tip-container">
            <div class="tip-img">
                <img :src="noOpenImgSrc" alt="">
            </div>
            <div class="tip-text">
                此功能暫未開放，敬請期待~
            </div>
        </div>
    </div>
</template>
<script>
import CommonHeader from '@/components/header'
export default {
    data() {
        return {
            title: '',
            noOpenImgSrc: require('@/assets/images/no-open.png')
        }
    },
    components: {
        CommonHeader
    },
    mounted() {
        this.title = this.$route.query['title']
    }
}
</script>
<style lang="scss" scoped>
.container {
    min-height: 100vh;
    background: #fff;
}
.tip-container {
    padding-top: 115px;
    box-sizing: border-box;

    .tip-img {
        width: 238px;
        height: 264px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .tip-text {
        font-size: 20px;
        color: #999;
        margin-top: 22px;
    }
}
</style>